<template>
  <CCard class="form-size">
    <loading-overlay :active="isLoading" :is-full-page="true" loader="bars" />
    <CCardHeader>
      <small>ASOCIAR BAYS PAR</small>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="12">
          <div class="status-wrapper">
            <label>Bays Pares</label>
            <div class="status-container">
                <div
                  v-for="item in formatedBays"
                  :key="item.BayId"
                  class="status-item"
                  :class="isSelected(item)"
                  @click.prevent="selectBay(item)"
                >
                  <span>{{ item.BayCode }}</span>
                </div>
            </div>
          </div>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import { mapState } from 'vuex';

//Data
function data() {
  return {
    isLoading: false,
    bayStatus: {
      BayStatusId: '',
      BayStatusName: ''
    },
    status: [],
    baysPares: [],
    seleccionados: []
  }
}

//Created:
function created() {
  this.generateBaysPares();
}

//Metodos: 
function isSelected(item) {
  return {
    'selected': item.selected ? true : null
  }
}

function generateBaysPares() {
  for (let index = 1; index <= this.bayQuantity; index++) {
    if(index%2 == 0)
      this.baysPares.push({
        BayCode: index < 10?`00${index}`:`0${index}`,
        VesselBayId: this.currentBay.VesselBayId
      });
  }
}
function selectBay(item) {
  if(Number.parseInt(this.currentBay.BayCode) - 1 == Number.parseInt(item.BayCode)
    || Number.parseInt(this.currentBay.BayCode) + 1 == Number.parseInt(item.BayCode)) {
    let found = this.seleccionados.findIndex((bay) => bay.BayCode === item.BayCode);
  
    if(found == -1) {
      if(this.seleccionados.length == 0)
        this.confirmation(false, item);
    } else {
      this.confirmation(true, item);
    }
  }
}
function submit(edit) {
  this.isLoading = true;
  let metodo = edit ? 'PUT':'POST';
  let ruta = edit ? 'VesselBayPair-update' : 'VesselBayPair-insert'; 

  let VesselBayJson = edit
    ? {
      ...this.formatedData(),
      VesselBayIdPair: this.seleccionados[0].VesselBayIdPair,
      Status: 0
    }
    : { ...this.formatedData() };

  this.$http.ejecutar(metodo, ruta, VesselBayJson, { root: 'VesselBayJson' })
  .then(response => {
    this.$notify({
      group: 'container',
      title: '¡'+this.$t('label.success')+'!',
      text: response.data.data[0].Response,
      type: "success"
    });
    if(!edit){
      this.seleccionados.splice(0, 1, {
        VesselBayIdPair: response.data.data[0].VesselBayIdPair,
        VesselBayId: response.data.data[0].VesselBayId,
        BayCode: VesselBayJson.BayCodePair
      });
    } else {
      this.seleccionados.splice(0, 1);
    }
  }).catch(err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoading = false;
  });
}
function confirmation(edit, item){
  let texto = edit
    ? '¿Esta seguro de desactivar el bay par?'
    : '¿Esta seguro de asociar el bay par?';

  this.$swal.fire({
    text: `${texto}`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#42AA91',
    cancelButtonColor: '#E1373F',
    confirmButtonText: 'CONFIRMAR',
    cancelButtonText: 'CANCELAR'
  }).then((result) => {
    if (result.isConfirmed) {
      if(!edit)
        this.seleccionados.push(item);

      this.submit(edit);
    }
  });
}
function formatedData() {
  if(this.seleccionados.length != 0){
    return {
      VesselBayId: this.seleccionados[0].VesselBayId,
      BayCodePair: this.seleccionados[0].BayCode
    };
  }else{
    return {};
  }
}

//Computeds: 
function formatedBays() {
  return this.baysPares.map((item) => {
    let found = this.seleccionados.findIndex((bay) => bay.BayCode === item.BayCode);

    return Object.assign({}, item, { 
        selected: found != -1 ? true : false
    });

  });
}

export default {
  name: 'bay-pair',
  components: {},
  data,
  created,
  methods: {
    generateBaysPares,
    isSelected,
    selectBay,
    submit,
    confirmation,
    formatedData
  },
  computed: {
    formatedBays,
    ...mapState({
      currentBay: state => state.baygrid.bay,
      bayQuantity: state => state.baygrid.bayQuantity
    })
  }
}
</script>

<style scoped>
.status-wrapper{
  width: 100%;
  padding: 10px;
  border:2px solid #000;
  margin-top: 10px;
}
.status-wrapper>label{
  font-size: 16px;
  font-weight: bolder;
  color: #000;
  position:absolute;
  top:-4px;
  left:30px;
  background-color:white;
}

.status-wrapper .status-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.status-wrapper .status-container .status-item {
  cursor: pointer;
  width: 50px;
  margin: 5px;
  height: 50px;
  border: 1px solid #000;
  /* background: lightblue; */

  display: flex;
  align-items: center;
  justify-content: center;
}
.status-wrapper .status-container .status-item:hover {
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
  transform: scale(1.01);
}

.status-wrapper .status-container .status-item.selected {
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
  transform: scale(1.01);
  background: #2eb85c;
  color: #fff;
}
.form-size {
  min-height: 420px;
}
</style>